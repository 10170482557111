import React from 'react';
import { Accordion } from 'react-bootstrap';
import styles from './SideNavAccordion.module.css';
import SideNavAccordionItem from './SideNavAccordionItem/SideNavAccordionItem';

const navigationItems = (props) => {
  let accordionData = [
    {
      accordion_header: 'Apps',
      accordion_links: [
        {
          icon: 'user-circle',
          link: 'https://udp.digmstools.com/',
          title: 'User Data Platform'
        },
        {
          icon: 'chart-bar',
          link: 'https://cdp.digmstools.com/',
          title: 'Client Data Platform'
        }
      ]
    },
    {
      accordion_header: 'SEO',
      accordion_links: [
        {
          icon: 'tachometer-alt',
          link: 'https://www.seo-internal-tools.digmstools.com/seo-dashboard',
          title: 'SEO Dashboard'
        },
        {
          icon: 'keyboard',
          link: 'https://www.seo-internal-tools.digmstools.com/seomonitor-keyword-export-tool',
          title: 'Keyword Export Tool'
        },
        {
          icon: 'not-equal',
          link: 'https://www.seo-internal-tools.digmstools.com/title-description-tool',
          title: 'SERP Checker'
        }
      ]
    },
    {
      accordion_header: 'SEM',
      accordion_links: [
        {
          icon: 'tachometer-alt',
          link: 'https://www.sem-internal-tools.digmstools.com/',
          title: 'SEM Dashboard'
        },
        {
          icon: 'camera-retro',
          link: 'https://www.sem-internal-tools.digmstools.com/screenshot-tool',
          title: 'Screenshot Tool'
        }
      ]
    }
  ] 

  return (
    <div className={styles.sideNavAccordion}>
      <Accordion defaultActiveKey="0" flush>
        {accordionData.map((accordionItem, index) => (
          <SideNavAccordionItem 
            key={index}
            eventKey={index}
            header={accordionItem.accordion_header}
            navItems={accordionItem.accordion_links}
          />
        ))}
      </Accordion>    
    </div>
  );
}

export default navigationItems;