import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from '../../hoc/asyncComponent/asyncComponent';
import withAWSAuth from '../../hoc/withAWSAuth/withAWSAuth';
import Layout from '../../components/Layout/Layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faTachometerAlt, faCameraRetro, faKeyboard, faNotEqual, faUserCircle, faChartBar, faExclamation } from '@fortawesome/free-solid-svg-icons';
library.add(faCogs, faTachometerAlt, faCameraRetro, faKeyboard, faNotEqual, faUserCircle, faChartBar, faExclamation);

const asyncOverview = asyncComponent(() => {
  return import('../Overview/Overview');
});

const asyncPageNotFound = asyncComponent(() => {
  return import ('../ErrorPages/PageNotFound/PageNotFound');
})

const asyncForbidden = asyncComponent(() => {
  return import ('../ErrorPages/Forbidden/Forbidden');
})

const asyncUnauthorizedUser = asyncComponent(() => {
  return import ('../ErrorPages/UnauthorizedErrorPage/UnauthorizedErrorPage');
})

class App extends Component {
  render() {
    return (
      <div>
        <Layout>
          <Switch>
            <Route path="/" exact component={asyncOverview} />
            <Route path="/unauthorized" component={asyncUnauthorizedUser} />
            <Route path="/forbidden" component={asyncForbidden} />
            <Route path="*" component={asyncPageNotFound} />
          </Switch>
        </Layout>
      </div>
    );
  };
}

export default withAWSAuth(withRouter(connect()(App)));